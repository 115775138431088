import { GetInvitationRoleOptions } from '../constants/roles';
import { EMAIL_VALIDATION, PHONE_VALIDATION_V2, REQUIRED_VALIDATION, validateWhiteSpace,POST_CODE_VALIDATION } from './_predefinedValidations';

export type InvitationFormTypes = {
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  merchantName: string,
  sapCode:string,
  role: UserRole,
  storeName: string,
  storePostCode: string,
  territoryManager:string
};

type InvitationForm = [
  { title: string, subtitle: string, fields: [FormInputField, FormInputField, FormInputField, FormInputField]; },
  { title: string, subtitle: string, fields: [FormInputField, FormInputField]; },
  { title: string, subtitle: string, fields: [FormSelectField]; },
  { title: string, subtitle: string, fields: [FormInputField, FormInputField, FormInputField]; },
];

export const INVITATION_FORM: InvitationForm = [
  {
    title: 'User account details',
    subtitle: 'Enter your personal information.',
    fields: [
      {
        label: 'First name*',
        name: 'firstName',
        inputType: 'text',
        validation: {
          required: REQUIRED_VALIDATION,
          validate: { validateWhiteSpace }
        }
      },
      {
        label: 'Last name*',
        name: 'lastName',
        inputType: 'text',
        validation: {
          required: REQUIRED_VALIDATION,
          validate: { validateWhiteSpace }
        }
      }, {
        label: 'Email*',
        name: 'email',
        inputType: 'text',
        validation: {
          required: REQUIRED_VALIDATION,
          pattern: EMAIL_VALIDATION
        }
      },
      {
        label: 'Phone number',
        name: 'phone',
        inputType: 'tel',
        validation: {
          pattern: PHONE_VALIDATION_V2,
        }
      }
    ]
  },
  {
    title: 'Merchant information',
    subtitle: '',
    fields: [
      {
        label: 'Merchant name*',
        name: 'merchantName',
        inputType: 'text',
        validation: {
          required: REQUIRED_VALIDATION,
          validate: { validateWhiteSpace }
        }
      },
      {
        label: 'SAP Payer Code',
        name: 'sapCode',
        inputType: 'text',
        validation: {
        }
      }
    ]
  },
  
  {
    title: 'My role',
    subtitle: 'Select your role in the organisation.',
    fields: [
      {
        label: 'Select role*',
        name: 'role',
        inputType: 'select',
        options: GetInvitationRoleOptions(),
        validation: {
          required: REQUIRED_VALIDATION
        }
      }
    ]
  },
  {
    title: 'Store',
    subtitle: 'Enter the store name and its post code. In case you have multiple stores you can add them later.',
    fields: [
      {
        label: 'Store name',
        name: 'storeName',
        inputType: 'text',
        validation: {
          
        }
      },
      {
        label: 'Store post code',
        name: 'storePostCode',
        inputType: 'text',
        validation: {
          pattern: POST_CODE_VALIDATION
        }
      },
      {
        label: 'AkzoNobel Territory Manager',
        name: 'territoryManager',
        inputType: 'text',
        validation: {
          
        }
      }
    ]
  }
];
