import { GetRoleOptions } from '../constants/roles';
import { EMAIL_VALIDATION, PHONE_VALIDATION_V2, REQUIRED_VALIDATION, validateWhiteSpace } from './_predefinedValidations';

export type EmployeeFormTypes = {
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  role: UserRole,
};

type EmployeeForm = [
  { title: 'Employee details', fields: [FormInputField, FormInputField, FormInputField, FormInputField]; },
  { title: 'Employee role', fields: [FormSelectField]; },
];

export const EMPLOYEE_FORM: EmployeeForm = [
  {
    title: 'Employee details',
    fields: [
      {
        label: 'First Name',
        name: 'firstName',
        inputType: 'text',
        validation: {
          required: REQUIRED_VALIDATION,
          validate: { validateWhiteSpace }
        }
      },
      {
        label: 'Last Name',
        name: 'lastName',
        inputType: 'text',
        validation: {
          required: REQUIRED_VALIDATION,
          validate: { validateWhiteSpace }
        }
      }, {
        label: 'Email Address',
        name: 'email',
        inputType: 'text',
        validation: {
          required: REQUIRED_VALIDATION,
          pattern: EMAIL_VALIDATION
        }
      },
      {
        label: 'Phone',
        name: 'phone',
        inputType: 'tel',
        helperText: 'Example: 07975777666',
        validation: {
          pattern: PHONE_VALIDATION_V2,
        }
      },
    ]
  },
  {
    title: 'Employee role',
    fields: [
      {
        label: 'Role',
        name: 'role',
        inputType: 'select',
        options: GetRoleOptions(),
        validation: {
          required: REQUIRED_VALIDATION
        }
      }
    ]
  }
];
