import { CUSTOMER_CODE_VALIDATION, EMAIL_VALIDATION, UK_POST_CODE_VALIDATION, REQUIRED_VALIDATION, validateWhiteSpace, PHONE_VALIDATION_V2 } from './_predefinedValidations';

export type StoreFormTypes = {
  name: string,
  customerCode: string,
  addressLine1: string,
  addressLine2: string,
  city: string,
  postCode: string,
  county: string,
  email: string,
  phone: string;
};

type StoreForm = [
  { title: 'Basic information', fields: [FormInputField, FormInputField]; },
  { title: 'Store address', fields: [FormInputField, FormInputField, FormInputField, FormInputField, FormInputField]; },
  { title: 'Contact details', fields: [FormInputField, FormInputField]; }
];

export const STORE_FORM: StoreForm = [
  {
    title: 'Basic information',
    fields: [
      {
        label: 'Store Name',
        name: 'name',
        inputType: 'text',
        validation: {
          required: REQUIRED_VALIDATION,
          validate: { validateWhiteSpace }
        }
      },
      {
        label: 'Customer Code',
        name: 'customerCode',
        inputType: 'text',
        validation: {
          required: REQUIRED_VALIDATION,
          pattern: CUSTOMER_CODE_VALIDATION
        }
      },
    ]
  },
  {
    title: 'Store address',
    fields: [
      {
        label: 'Address Line 1',
        name: 'addressLine1',
        inputType: 'text',
        validation: {
          required: REQUIRED_VALIDATION,
          validate: { validateWhiteSpace }

        }
      },
      {
        label: 'Address Line 2',
        name: 'addressLine2',
        inputType: 'text',
        validation: {
        }
      },
      {
        label: 'City',
        name: 'city',
        inputType: 'text',
        validation: {
          required: REQUIRED_VALIDATION,
          validate: { validateWhiteSpace }

        }
      },
      {
        label: 'Post Code',
        name: 'postCode',
        inputType: 'text',
        validation: {
          required: REQUIRED_VALIDATION,
          pattern: UK_POST_CODE_VALIDATION
        }
      },
      {
        label: 'County',
        name: 'county',
        inputType: 'text',
        validation: {
          required: REQUIRED_VALIDATION,
          validate: { validateWhiteSpace }

        }
      }
    ]
  },
  {
    title: 'Contact details',
    fields: [
      {
        label: 'Email',
        name: 'email',
        inputType: 'text',
        validation: {
          required: REQUIRED_VALIDATION,
          pattern: EMAIL_VALIDATION
        }
      },
      {
        label: 'Phone',
        name: 'phone',
        inputType: 'tel',
        helperText: 'Example: 07975777666',
        validation: {
          pattern: PHONE_VALIDATION_V2,
        }
      },
    ]
  }
];
