import { PHONE_VALIDATION_V2 } from './_predefinedValidations';

export type EditPhoneFormTypes = {
  phone: string,
};

export const EDIT_PHONE_FORM: [FormInputField] = [
  {
    label: 'Phone',
    name: 'phone',
    inputType: 'text',
    helperText: 'Example: 07975777666',
    validation: {
      pattern: PHONE_VALIDATION_V2
    }
  }
];
