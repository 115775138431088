import { Fragment, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import Grid from '@mui/material/Grid';

import { TerritoryManagerFormTypes, TERRITORY_MANAGER_FORM } from '../../forms/territoryManager';
import { useTypedSelector } from '../../hooks/TypedReduxHooks';

import { SectionHeader } from '../../components-molecules/section/SectionHeader';
import { ModalCardComponent } from '../../components-molecules/Modal';
import { FormControlInput } from '../../components-molecules/FormControl';
import { EditAdminRequest } from '../../service/adminService';
import { FormPageComponent } from '../../components-molecules/FormPageComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormHelperText } from '@mui/material';

type GeneralType = {
  [key: string]: string;
};

export function EditTerritoryManager(): JSX.Element {
  const token = useTypedSelector((state) => state.userReducer.token);
  const navigate = useNavigate();
  const { state } = useLocation();
  const territoryManager = state.territorymanager;

  const defaultValues: GeneralType = {
    firstName: territoryManager.AdminProperties.FirstName,
    lastName: territoryManager.AdminProperties.LastName,
    email: territoryManager.AdminProperties.Email,
    phone: territoryManager.AdminProperties.Phone
  };

  const { control, formState: { isValid }, handleSubmit,trigger } = useForm({ mode: 'all', reValidateMode: 'onBlur', defaultValues });
  useEffect(() => {
    trigger();
  }, [trigger]);
  

  const submit: SubmitHandler<TerritoryManagerFormTypes> = (data): void => {
    if (token) {
      EditAdminRequest(
        token,
        {
          Id: territoryManager.AdminProperties.Id,
          FirstName: data.firstName,
          LastName: data.lastName,
          Phone: data.phone || '',
        },
        () => navigate('/admin/salesmanagers', { state: { from: 'edit-territorymanager' } }),
        (error) => console.error(error)
      );
    }
  };

  return (
    <FormPageComponent title="Edit Territory Manager" buttonTitle="Submit" buttonDisabled={!isValid} close={() => navigate('/admin/salesmanagers', { state: { from: 'edit-territorymanager' } })} submit={handleSubmit(submit)}>
      <ModalCardComponent>
        <SectionHeader title="Manager Details" />
        <Fragment>
          {TERRITORY_MANAGER_FORM.map((field, index) => {
            return (<>
              <Grid key={index} item sx={{ flexGrow: 1 }}>
                <FormControlInput disabled={field.name === 'email'} control={control} field={field} />
              </Grid>
              {field.helperText 
              && <FormHelperText sx={{ padding: '0 14px' }}>
                {field.helperText}
              </FormHelperText>
              }
            </>
              
            );
          })
          }
        </Fragment>
      </ModalCardComponent>
    </FormPageComponent >
  );
}
