import { PAYER_CODE_VALIDATION, UK_POST_CODE_VALIDATION, REQUIRED_VALIDATION, validateWhiteSpace, EMAIL_VALIDATION, LOGO_VALIDATION_WITH_TYPE, PHONE_VALIDATION_V2 } from './_predefinedValidations';

export type AddMerchantFormTypes = {
  logo: File | string;
  merchantName: string;
  payerCode: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  postCode: string;
  county: string;
  phone: string;
  email: string;
};

type AddMerchantForm = [
  { title: 'Basic information', fields: [FormImageField, FormInputField, FormInputField]; },
  { title: 'Head office address', fields: [FormInputField, FormInputField, FormInputField, FormInputField, FormInputField]; },
  { title: 'Contact details', fields: [FormInputField, FormInputField]; }
];

export const ADD_MERCHANT_FORM: AddMerchantForm = [
  {
    title: 'Basic information',
    fields: [
      {
        label: 'Logotype',
        name: 'logo',
        inputType: 'logo',
        validation: {
          required: REQUIRED_VALIDATION,
          validate: LOGO_VALIDATION_WITH_TYPE('image/*')
        }
      },
      {
        label: 'Merchant name',
        name: 'name',
        inputType: 'text',
        validation: {
          required: REQUIRED_VALIDATION,
          validate: { validateWhiteSpace }
        }
      },
      {
        label: 'Payer/Parent code',
        name: 'payerCode',
        inputType: 'text',
        validation: {
          required: REQUIRED_VALIDATION,
          pattern: PAYER_CODE_VALIDATION,
          validate: { validateWhiteSpace }
        }
      },
    ]
  },
  {
    title: 'Head office address',
    fields: [
      {
        label: 'Address line 1',
        name: 'addressLine1',
        inputType: 'text',
        validation: {
          required: REQUIRED_VALIDATION,
          validate: { validateWhiteSpace }
        }
      },
      {
        label: 'Address line 2',
        name: 'addressLine2',
        inputType: 'text',
        validation: {
        }
      },
      {
        label: 'City',
        name: 'city',
        inputType: 'text',
        validation: {
          required: REQUIRED_VALIDATION,
          validate: { validateWhiteSpace }
        }
      },
      {
        label: 'Post code',
        name: 'postCode',
        inputType: 'text',
        validation: {
          required: REQUIRED_VALIDATION,
          pattern: UK_POST_CODE_VALIDATION,
        }
      },
      {
        label: 'County',
        name: 'county',
        inputType: 'text',
        validation: {
          required: REQUIRED_VALIDATION,
          validate: { validateWhiteSpace }
        }
      }
    ]
  },
  {
    title: 'Contact details',
    fields: [
      {
        label: 'Email',
        name: 'email',
        inputType: 'text',
        validation: {
          required: REQUIRED_VALIDATION,
          pattern: EMAIL_VALIDATION,
          validate: { validateWhiteSpace }
        }
      },
      {
        label: 'Phone number',
        name: 'phone',
        inputType: 'tel',
        helperText: 'Example: 07975777666',
        validation: {
          pattern: PHONE_VALIDATION_V2,
        }
      },
    ]
  }
];
