import { EMAIL_VALIDATION, PHONE_VALIDATION_V2, REQUIRED_VALIDATION, validateWhiteSpace } from './_predefinedValidations';

export type TerritoryManagerFormTypes = {
  firstName: string,
  lastName: string,
  email: string,
  phone: string;
};

export const TERRITORY_MANAGER_FORM: [FormInputField, FormInputField, FormInputField, FormInputField] = [
  {
    label: 'First Name',
    name: 'firstName',
    inputType: 'text',
    validation: {
      required: REQUIRED_VALIDATION,
      validate: { validateWhiteSpace }

    }
  },
  {
    label: 'Last Name',
    name: 'lastName',
    inputType: 'text',
    validation: {
      required: REQUIRED_VALIDATION,
      validate: { validateWhiteSpace }

    }
  }, {
    label: 'Email Address',
    name: 'email',
    inputType: 'text',
    validation: {
      required: REQUIRED_VALIDATION,
      pattern: EMAIL_VALIDATION
    }
  },
  {
    label: 'Phone',
    name: 'phone',
    inputType: 'tel',
    helperText: 'Example: 07975777666',
    validation: {
      pattern: PHONE_VALIDATION_V2,

    }
  }
];
